<template>
  <Portlet
    :title="$t('softwareComponent.softwareComponent')"
    icon="hotel"
    class="softwareComponentTypes"
  >
    <SoftwareComponentFullList
      ref="softwareComponentFullList"
    /> 
  </Portlet>
</template>

<script>
export default {
    name: 'SoftwareComponentsView',
    components: {
        SoftwareComponentFullList: () => import('@/components/SoftwareComponent/SoftwareComponentFullList.vue')
    },
    metaInfo () {
        return {
            title: this.$t('softwareComponent.softwareComponent')
        }
    }
}
</script> 